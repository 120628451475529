<!-- 公用表格 -->
<template>
  <div class="table-container">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="date" label="任务工单名称"> </el-table-column>
      <el-table-column prop="date" label="任务工单名称"> </el-table-column>
      <el-table-column prop="date" label="任务工单名称"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {}
};
</script>

<style lang="less" scoped></style>
